import Image from "next/image";
import ButtonLink from "@components/buttons/ButtonLink";
import Header from "@components/headers/Header";
import PublicHeader from "@components/headers/PublicHeader";
import { useAuth } from "@lib/authContext";
import styles from "./errorPage.module.scss";

const NotFound = () => {
  const { isAuthenticated } = useAuth();

  return (
    <section className={styles.container}>
      {isAuthenticated ? <Header /> : <PublicHeader />}
      <div className={styles.content}>
        <div className={styles.illustration}>
          <Image
            src="/404.svg"
            layout="fill"
            data-testid="404-illustration"
            alt="Ilustração - 404 Não encontrado"
          />
        </div>

        <div className={styles.title} data-testid="404-text">
          <h4>Página não encontrada</h4>
          <span>
            Ops... Parece que a página que você está procurando não existe.
            <br />
            Verifique se o endereço da página está correto.
          </span>
          <ButtonLink
            themeColor="flatTextSecondary"
            to={isAuthenticated ? "/dashboard" : "/"}
          >
            Voltar para o início
          </ButtonLink>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
